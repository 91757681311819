import { Input } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import isInputElement from "../../utilities/domUtils";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import './Pagination.scss';

const PaginationV2 = ({ page, total, onPageChange }) => {

    const previousEnabled = page + 1 > 1;
    const nextEnabled = page + 1 < total;

    const onPreviousPageChange = useCallback(() => {
        onPageChange(page - 1);
    }, [onPageChange, page])

    const onNextPageChange = useCallback(() => {
        onPageChange(page + 1);
    }, [onPageChange, page])

    const onKeyDownListener = useCallback(ev => {
        if (isInputElement(document.activeElement)) {
            return;
        }

        if (previousEnabled && ev.key === 'p') {
            ev.preventDefault();
            onPreviousPageChange();
        } else if (nextEnabled && ev.key === 'n') {
            ev.preventDefault();
            onNextPageChange();
        }
    }, [previousEnabled, nextEnabled, onPreviousPageChange, onNextPageChange]);

    useEffect(() => {
        document.addEventListener('keydown', onKeyDownListener);
        return () => {
            document.removeEventListener('keydown', onKeyDownListener);
        };
    }, [onKeyDownListener]);

    if (parseInt(total) === 1) {
        return <label>(no more pages)</label>
    }

    return (
        <div className='pagination'>
            <button className='text-gray-500' disabled={!previousEnabled} onClick={onPreviousPageChange}>
                <KeyboardArrowLeftIcon />
            </button>
            <div className=''>
                <label>Page</label>
                <Input value={page + 1} maxLength={2} size="xs" w={6} textAlign="center" max={total} onInput={ev => onPageChange(isNaN(parseInt(ev.target.value)) ? 1 : parseInt(ev.target.value) - 1)} />
                <label className='ml-1'>of </label>
                {total}
            </div>

            <button className='text-gray-500' disabled={!nextEnabled} onClick={onNextPageChange}>
                <KeyboardArrowRightIcon />
            </button>
        </div>
    )
}

export default PaginationV2;
