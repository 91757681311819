import React, { useState } from 'react';

import useFetch from 'hooks/useFetch';
import secureApiFetch from 'services/api';
import { actionCompletedToast } from 'components/ui/toast';


function CategoryForm({ updateQuestions }) {
    const [category] = useFetch('/project/categories');
    const [selectedCategoryId, setSelectedCategoryId] = useState();
    const [selectedType, setSelectedType] = useState('checkbox');
    const [title, setTitle] = useState('');
    const [value, setValue] = useState('');
    const [activeAccordion, setActiveAccordion] = useState(1);

    const handleAccordionClick = (accordionIndex) => {
        setActiveAccordion(accordionIndex === activeAccordion ? 0 : accordionIndex);
    };
    const handleOnClick = (ev) => {
        ev.preventDefault();
        if (!selectedCategoryId) {
            return;
        }
        const values = value.split(',');

        const userData = {
            category_id: selectedCategoryId,
            title,
            type: selectedType,
            options: values
        };

        secureApiFetch(`/questions`, {
            method: 'POST',
            body: JSON.stringify(userData)
        }).then((res) => {
            if(res.status === 201){
                actionCompletedToast(`The Category Question has been created.`);
            }
            updateQuestions()
        });
    };

    return (
        <div>
            <div id="accordion-collapse" className=' mb-2 rounded-xl' data-accordion="collapse">
                <h2 id="accordion-collapse-heading-1">
                    <button
                        type="button"
                        className={`flex items-center justify-between w-full text-lg py-2 px-5 font-medium rtl:text-right text-gray-500 border border-gray-200 rounded-t-xl hover:bg-gray-100 `}
                        data-accordion-target="#accordion-collapse-body-1"
                        aria-expanded={activeAccordion === 1}
                        aria-controls="accordion-collapse-body-1"
                        onClick={() => handleAccordionClick(1)}
                    >
                        <span className="font-medium text-gray-700 text-base">Add Project Category</span>
                        <svg data-accordion-icon className={`w-3 h-3 rotate-180 shrink-0`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                        </svg>
                    </button>
                </h2>
                <div
                    id="accordion-collapse-body-1"
                    className={`p-2 border border-t-0 border-gray-200  ${activeAccordion === 1 ? '' : 'hidden'}`}
                    aria-labelledby="accordion-collapse-heading-1"
                >
                    <form>
                        <label>
                            <span className="font-medium text-xs">Select category</span>
                            <select id="countries" value={selectedCategoryId} onChange={(e) => setSelectedCategoryId(e.target.value)} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'>
                                <option value="">Select category</option>
                                {category && category.map((user) => (
                                    <option key={user.id} value={user.id}>
                                        {user.name} - {user.id}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <label>
                            <span className="font-medium text-xs">Select Type</span>
                            <select id="countries" value={selectedType} onChange={(e) => setSelectedType(e.target.value)} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'>
                                <option value='checkbox'>Checkbox</option>
                                <option value='radio'>Radio</option>
                                <option value='textarea'>Textarea</option>
                            </select>
                        </label>
                        <label>
                            <span className="font-medium text-xs">Title</span>
                            <input type="text" className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'
                                id="title"
                                placeholder="Add Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)} />
                        </label>
                        <label>
                            <span className="font-medium text-xs">Value</span>
                            <input type="text" className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'
                                id="value"
                                placeholder="Add Value"
                                value={value}
                                onChange={(e) => setValue(e.target.value)} />
                        </label>
                        <button onClick={handleOnClick} className='bg-[#3A3D89] w-28 px-4 py-2 rounded text-white text-sm'>
                            Add
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CategoryForm