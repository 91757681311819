const Title = ({ type, title, icon }) => {
    const styles = {
        container: {
            margin: '2px 0',
            display: 'flex',
            alignItems: 'center',
        },
        subtitle: {
            margin: 0,
            fontSize:'var(--fontSizeSubtitle)',
            marginBottom: '-6px',
            color: 'var(--primary-color)',
        },
        title: {
            marginTop: 'var(--space-sm)',
            fontSize:'var(--fontSizeTitle)'
        },
        icon: {
            backgroundColor: '#dfdfee',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 'var(--padding)',
            width: 'var(--iconSizeLarge)',
            height: 'var(--iconSizeLarge)',
            color: '#3d3a89'
        }
    }
    return (
        <div style={styles.container} className="text-base">
            {icon && <figure style={styles.icon}>{icon}</figure>}
            <div>
                {type && <p style={styles.subtitle}>{type}</p>}
                <h2 style={styles.title} className="font-medium text-gray-700 text-2xl">{title}</h2>
            </div>
        </div>
    )
}

export default Title
