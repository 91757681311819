import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Flex, HStack, useDisclosure } from '@chakra-ui/react';

import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import Pagination from 'components/layout/Pagination';
import PageTitle from 'components/logic/PageTitle';
import Breadcrumb from 'components/ui/Breadcrumb';
import Title from 'components/ui/Title';
import useQuery from 'hooks/useQuery';
import secureApiFetch from '../../services/api';
import CreateButton from '../ui/buttons/Create';
import VulnerabilityFilters from './Filters';
import VulnerabilitiesTable from './VulnerabilitiesTable';
import VulnerabilityTableModel from './VulnerabilityTableModel';
import VulnerabilityModalDialog from './templates/VulnerabilityModalDialog';

const VulnerabilitiesList = () => {
    const navigate = useNavigate();
    const query = useQuery();
    let pageNumber = query.get('page');
    pageNumber = pageNumber !== null ? parseInt(pageNumber) : 1;
    const apiPageNumber = pageNumber - 1;

    const [tableModel, setTableModel] = useState(new VulnerabilityTableModel())

    const [totalCount, setTotalCount] = useState('?');
    const [numberPages, setNumberPages] = useState(1);
    const { isOpen: isAddDialogOpen, onOpen: openAddDialog, onClose: closeAddDialog } = useDisclosure();



    const handlePrev = () => {
        const queryParams = new URLSearchParams();
        queryParams.set('isTemplate', 'false');
        queryParams.set('page', pageNumber - 1);
        queryParams.set('orderColumn', tableModel.sortBy.column);
        queryParams.set('orderDirection', tableModel.sortBy.order);
        Object.keys(tableModel.filters)
            .forEach(key => tableModel.filters[key] !== null && tableModel.filters[key].length !== 0 && queryParams.append(key, tableModel.filters[key]));
        const url = `/vulnerabilities?${queryParams.toString()}`;
        navigate(url);
    }

    const handleNext = () => {
        const queryParams = new URLSearchParams();
        queryParams.set('isTemplate', 'false');
        queryParams.set('page', pageNumber + 1);
        queryParams.set('orderColumn', tableModel.sortBy.column);
        queryParams.set('orderDirection', tableModel.sortBy.order);
        Object.keys(tableModel.filters)
            .forEach(key => tableModel.filters[key] !== null && tableModel.filters[key].length !== 0 && queryParams.append(key, tableModel.filters[key]));
        const url = `/vulnerabilities?${queryParams.toString()}`;
        navigate(url);
    }

    const reloadVulnerabilities = useCallback(() => {
        setTableModel(tableModel => ({ ...tableModel, vulnerabilities: null }));

        const queryParams = new URLSearchParams();
        queryParams.set('isTemplate', 'false');
        queryParams.set('page', apiPageNumber);
        queryParams.set('orderColumn', tableModel.sortBy.column);
        queryParams.set('orderDirection', tableModel.sortBy.order);
        Object.keys(tableModel.filters)
            .forEach(key => tableModel.filters[key] !== null && tableModel.filters[key].length !== 0 && queryParams.append(key, tableModel.filters[key]));
        const url = `/vulnerabilities?${queryParams.toString()}`;

        secureApiFetch(url, { method: 'GET' })
            .then(resp => {
                if (resp.headers.has('X-Page-Count')) {
                    setNumberPages(resp.headers.get('X-Page-Count'))
                }
                if (resp.headers.has('X-Total-Count')) {
                    setTotalCount(resp.headers.get('X-Total-Count'))
                }
                return resp.json()
            })
            .then(data => {
                setTableModel(tableModel => ({ ...tableModel, vulnerabilities: data }));
            });
    }, [setTableModel, apiPageNumber, tableModel.filters, tableModel.sortBy.column, tableModel.sortBy.order]);


    const onAddVulnerabilityClick = () => {
        navigate(`/vulnerabilities/create`)
    }

    useEffect(() => {
        reloadVulnerabilities()
    }, [reloadVulnerabilities, tableModel.filters])

    return (
        <div className='-mt-2'>
            <PageTitle value={`Vulnerabilities - Page ${pageNumber}`} />
            <div className='heading'>
                <Breadcrumb>
                    <Link to="/vulnerabilities">Vulnerabilities</Link>
                </Breadcrumb>
                <Pagination page={apiPageNumber} total={numberPages} handlePrev={handlePrev} handleNext={handleNext} />
                <HStack>
                    <CreateButton onClick={onAddVulnerabilityClick}>Add vulnerability</CreateButton>

                    <div className="flex justify-end">
                        <VulnerabilityModalDialog
                            isOpen={isAddDialogOpen}
                            onCancel={closeAddDialog}
                            tableModel={tableModel}
                            reloadVulnerabilities={reloadVulnerabilities}
                            setTableModel={setTableModel}
                        />
                        <button onClick={openAddDialog} className='bg-[#3A3D89] px-2 py-1 rounded text-white space-x-1 flex items-center text-sm'>
                            Bulk-Action
                        </button>
                    </div>

                </HStack>
            </div>
            <Title title={`Vulnerabilities (${totalCount})`} icon={<BugReportOutlinedIcon />} />
            <Flex>
                <VulnerabilityFilters tableModel={tableModel} tableModelSetter={setTableModel} />
            </Flex>
            <VulnerabilitiesTable tableModel={tableModel} tableModelSetter={setTableModel} reloadCallback={reloadVulnerabilities} />
        </div>
    )
}

export default VulnerabilitiesList;
